import { Leveler } from "../services/leveler/models/leveler";

const options: Intl.DateTimeFormatOptions = {
  month: 'numeric',
  year: '2-digit'
};

export function LevelerCard(leveler: Leveler): JSX.Element {
  const levelingDate = new Intl.DateTimeFormat(navigator.language, options).format(
    new Date(leveler.levelChangeAt)
  )
  let levelText;

  if (leveler.isRecentLeveler) {
    levelText = <div className="text-end align-text-bottom">
                  <span className="font-normal text-nllilac dark:text-nllightlilac">{leveler.newLevel}</span>
                  <span className="font-thin text-sm"> since </span>
                  <span className="text-nllightlilac text-sm">{levelingDate}</span>
                </div>;
  } else {
    levelText = <div className="text-end align-text-bottom">
                  <span className="font-normal text-nldarkyellow dark:text-nlyellow">{leveler.newLevel}</span>
                  <span className="font-normal text-sm"> from </span>
                  <span className="text-nlyellow text-sm">{levelingDate}</span>
                </div>;
  }

  return (
    <div className="flex shadow border rounded-xl p-3 justify-between">
      <div className="flex flex-col ">
        <div className="text-base font-bold">{leveler.name.fullName}</div>
        <div className="grow h-2 text-sm font-regular">{leveler.office}</div>
        <div className="text-sm font-thin align-text-bottom">{leveler.mentor?.name.fullName}</div>
      </div>
      <div className="flex flex-col">
        <img
          className="rounded-full h-16 w-16 self-end"
          src={leveler.photos?.small}
          alt="Profile"
        />
        <div className="grow h-2"></div>
        {levelText}
      </div>
    </div>
  );
}
