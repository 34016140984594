import { useState, useCallback, useEffect, useContext } from "react";
import { AuthContext } from "../../services/auth/authenticationProvider";
import { getLevels } from "../../services/leveler/leveler-service";
import { ButtonTypes, FilterButton } from "./FilterButton";

interface ILevelFilterProps {
  currentSelectedLevels: string[];
  handleChange: (level: string) => void;
}

const LevelFilters = (props: ILevelFilterProps) => {
  const { accessToken } = useContext(AuthContext);

  const [levels, setLevels] = useState<string[] | undefined>(undefined);

  const loadLevels = useCallback(async () => {
    if (!accessToken) return;
    const levels = await getLevels();
    setLevels(levels);
  }, [accessToken]);

  useEffect(() => {
    if (!levels) {
      loadLevels();
    }
  }, [loadLevels, levels]);

  if (!accessToken || !levels) return <div>Loading Levels...</div>;

  return (
    <div>
      <div>
        <div className="flex flex-row justify-center pb-2 text-nlyellow">
          <div className="text-xl font-semibold">Levels</div>
        </div>
        <div className="grid grid-cols-3 md:grid-cols-8 lg:grid-cols-8 gap-4">
          {levels.map((level) => (
            <FilterButton
              key={level}
              name={level}
              checked={props.currentSelectedLevels.includes(level)}
              category="levels"
              onClick={props.handleChange}
              type={ButtonTypes.level}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default LevelFilters;
