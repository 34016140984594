import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: "14873d62-018c-4c4a-abaf-32d67b6578b1",
    authority:
      "https://login.microsoftonline.com/d4110288-bf1e-4337-97ab-a5c51371dcea",
    redirectUri: "/",
    postLogoutRedirectUri: "/",
  },
};
console.log(process.env.REACT_APP_HOST);

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: ["User.Read"],
};

// const myMsal = new PublicClientApplication(msalConfig);

// const logoutRequest = {
//   account: myMsal.getAccountByHomeId(homeAccountId),
// };

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me",
};
