import { Employee } from "./models/employee";
import { ProbableLeveler, RecentLeveler } from "./models/leveler";

export const fetchEmployees = async (token: string): Promise<Employee[]> => {
  const url = "https://api.prd.aws.netlight.com/colleagues";
  return await http<Employee[]>(url, token);
};

export const fetchRecentLeveler = async (token: string): Promise<RecentLeveler[]> => {
  const url = "https://api.prd.aws.netlight.com/colleagues/with-new-level";
  return await http<RecentLeveler[]>(url, token);
};

export const fetchProbableLeveler = async (token: string): Promise<ProbableLeveler[]> => {
  const url = "https://api.prd.aws.netlight.com/level-alignment/employees/with-new-level";
  return await http<ProbableLeveler[]>(url, token);
};

export const fetchOffices = async (token: string): Promise<string[]> => {
  const url = "https://api.prd.aws.netlight.com/offices";
  return await http<string[]>(url, token);
};

export const fetchLevels = async (token: string): Promise<string[]> => {
  const url = "https://api.prd.aws.netlight.com/levels";
  const levels = await http<string[]>(url, token);
  const levelsToIgnore = ["A", "Operations", "Hour", "Other"]
  return levels.filter(level => !levelsToIgnore.includes(level))
};

async function http<T>(request: RequestInfo, token: string): Promise<T> {
  const response = await fetch(request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return await response.json();
}