import { Leveler } from "../services/leveler/models/leveler";

export const Counter = (props: { leveler: Leveler[] | undefined }) => {
  return (
    <div className="flex justify-center flex-shrink items-center">
      <span className="text-lg font-extrabold text-nlcoral">
        Total: {props.leveler?.length} 🥳
      </span>
    </div>
  );
};
