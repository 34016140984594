import { Tab } from "@headlessui/react";
import { Timespan } from "../Persons";

interface ITimeframeFilterProps {
  currentDay: number;
  isTimespanHidden: boolean
  handleDaysChange: (day: number) => void;
  handleTimespanChange: (timespan: Timespan) => void;
}

const timespans = [Timespan.Past, Timespan.Outlook];
const days = [30, 60, 90, 120];
let showOutlookDisclaimer = false

const handleTimespan = (timespan: Timespan, props: ITimeframeFilterProps) => {
  showOutlookDisclaimer = timespan === Timespan.Outlook;
  props.handleTimespanChange(timespan);
};

function tabClassNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const TimeframeFilters = (props: ITimeframeFilterProps) => {
  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-row justify-center pb-2 text-nllilac">
        <div className="text-xl font-semibold">Timeframe</div>
      </div>
      {!props.isTimespanHidden && <Tab.Group>
          <Tab.List className="flex gap-2 justify-center pb-2">
            {timespans.map((timespan) => (
              <Tab
                onClick={() => handleTimespan(timespan, props)}
                key={timespan}
                className={({ selected }) =>
                  tabClassNames(
                    "w-20 rounded-2xl py-2.5 text-sm font-bold leading-5 text-nllilac",
                    "ring-white ring-opacity-60 ring-offset-2 ring-offset-nllilac focus:outline-none focus:ring-2",
                    selected ? "bg-white shadow" : "text-nllightlilac"
                  )
                }
              >
                {timespan}
              </Tab>
            ))}
          </Tab.List>
        </Tab.Group>
      }      
      <Tab.Group>
        <Tab.List className="flex gap-2 justify-center">
          {days.map((day) => (
            <Tab
              onClick={() => props.handleDaysChange(day)}
              key={day}
              className={({ selected }) =>
                tabClassNames(
                  "w-20 rounded-2xl py-2.5 text-sm font-bold leading-5 text-nllilac",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-nllilac focus:outline-none focus:ring-2",
                  selected ? "bg-white shadow" : "text-nllightlilac"
                )
              }
            >
              {day} days
            </Tab>
          ))}
        </Tab.List>
      </Tab.Group>
      {showOutlookDisclaimer &&
        <div className="flex flex-row justify-center pt-4 text-center">
          <div className="font-normal rounded-lg px-3 py-1 bg-nlyellow">          
            <span className="text-white">⚠️ </span>
            <span className="text-base">
            Future leveling outcomes are not final. Do not communicate anything until the respective mentor did.
            </span>
          </div>
        </div>
      }
    </div>
  );
};

export default TimeframeFilters;
