import { useMsal } from "@azure/msal-react";
import React, { createContext, useEffect, useState } from "react";

export interface AuthContextData {
  accessToken?: string;
}

export const AuthContext = createContext<AuthContextData>({});

interface Props {
  children: React.ReactNode;
}

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
  const { instance, accounts } = useMsal();

  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (accessToken) return;

    const request = {
      account: accounts[0],
      scopes: ["api://717aaf54-6553-432e-85ca-b2399c7f87f6/.default"],
    };

    instance.acquireTokenSilent(request).then((response) => {
      setAccessToken(response.accessToken);
    });
  }, [accounts, instance, accessToken]);
  return (
    <AuthContext.Provider value={{ accessToken: accessToken }}>
      {children}
    </AuthContext.Provider>
  );
};
