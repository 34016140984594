import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../services/auth/authenticationProvider";
import { sortLevelers, fetchLevelers } from "../services/leveler/leveler-service";
import { AllLeveler, Leveler } from "../services/leveler/models/leveler";
import { LevelerCard } from "./LevelerCard";
import { ResultHeader } from "./ResultHeader";

export enum Timespan {
  Past = "past",
  Outlook = "next",
}
export interface PersonsProps {
  levels: string[];
  offices: string[];
  days: number;
  timespan: Timespan;
}

const filterFunction = (
  leveler: Leveler,
  filters: PersonsProps
): boolean => {
  const endDate = new Date();
  const isPastTimespan = filters.timespan === Timespan.Past
  const offsetInDays = isPastTimespan ? -filters.days : filters.days;

  endDate.setDate(endDate.getDate() + offsetInDays);
  const levelerDate = new Date(leveler.levelChangeAt);

  const isLevelDateIncluded = isPastTimespan ? levelerDate > endDate : levelerDate < endDate
  const isOfficeIncluded = filters.offices?.includes(leveler.office) || filters.offices?.length === 0
  const isLevelIncluded = filters.levels?.includes(leveler.newLevel)|| filters.levels?.length === 0
  const isLevelerVisible = isLevelDateIncluded && isOfficeIncluded && isLevelIncluded

  return isLevelerVisible
};

const Persons = (props: {personProps: PersonsProps, handleHasProbableLevelers: (hasProbableLevelers: boolean) => void}) => {
  const { accessToken } = useContext(AuthContext);
  const [levelers, setLevelers] = useState<AllLeveler | undefined>();

  const selectedLevelers: Leveler[] | undefined = props.personProps.timespan === Timespan.Past 
    ? levelers?.recent : levelers?.probable

  const filteredLevelers = selectedLevelers
    ?.filter((leveler) => filterFunction(leveler, props.personProps))
    .sort(sortLevelers);
  
  const getLevelers = useCallback(async () => {
    const levelers = await fetchLevelers(accessToken!)
    props.handleHasProbableLevelers(levelers.probable.length > 0)
    setLevelers(levelers);
  }, [accessToken, props]);

  useEffect(() => {
    if (!levelers && accessToken) {
      getLevelers();
    }
  }, [levelers, accessToken, getLevelers]);

  return (
    <>
      <ResultHeader filteredLevelers={filteredLevelers} />
      <div>
        {filteredLevelers && (
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {filteredLevelers.map((leveler) => {
              return <LevelerCard 
                  key={leveler.netlightId} 
                  {...leveler}
                />;
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default Persons;
