export const LoginHeader = () => {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row items-center justify-between">
        <img className="h-10" src="nllogo.svg" alt="nllogo" />
      </div>
      <div className="rounded-md">
        <div className="flex flex-row p-2 justify-center">
          <div className="text-3xl font-extrabold text-nldarkpurple">
            Welcome to Level 🚀
          </div>
        </div>
      </div>
    </div>
  );
};
