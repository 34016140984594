import { Employee, EmployeeReference, Name, Photos, Email } from "../../../api/models/employee"

export interface AllLeveler {
  recent: Leveler[];
  probable: Leveler[];
}

export class Leveler implements Employee {
  email: Email
  phoneNumber?: string | undefined
  role: string
  office: string
  mentor?: EmployeeReference | undefined
  level: string
  netlightId: string
  name: Name
  photos?: Photos | undefined

  newLevel: string
  levelChangeAt: string
  isRecentLeveler: boolean

  constructor(employee: Employee, newLevel: string, levelChangeAt: string, isRecentLeveler: boolean) {
    this.email = employee.email
    this.phoneNumber = employee.phoneNumber
    this.role = employee.role
    this.office = employee.office
    this.mentor = employee.mentor
    this.level = employee.level
    this.netlightId = employee.netlightId
    this.name = employee.name
    this.photos = employee.photos

    this.newLevel = newLevel
    this.levelChangeAt = levelChangeAt
    this.isRecentLeveler = isRecentLeveler
  }
}