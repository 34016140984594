import { ExportToCsv } from "export-to-csv";
import { Leveler } from "../services/leveler/models/leveler";
import { sortLevelers } from "./leveler/leveler-service";

export const exportCSV = (levelers: Leveler[] | undefined) => {
  const data = levelers?.sort(sortLevelers)?.map((leveler) => ({
    name: leveler.name.fullName,
    email: leveler.email.short,
    role: leveler.role,
    office: leveler.office,
    newLevel: leveler.newLevel,
    levelChangeAt: leveler.levelChangeAt,
    mentor: leveler.mentor?.name.fullName,
  }));

  const csvExporter = new ExportToCsv(options);
  csvExporter.generateCsv(data);
};

const options = {
  filename: "WTL-Export",
  fieldSeparator: ";",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  title: "New Levelers",
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};
