import { useMsal } from "@azure/msal-react";
import { ArrowLeftOnRectangleIcon } from "@heroicons/react/24/solid";

export const SignOutButton = () => {
  const { instance, accounts } = useMsal();

  const signOutClickHandler = () => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
      postLogoutRedirectUri: `${process.env.REACT_APP_HOST}/`,
    };
    instance.logoutRedirect(logoutRequest);
  };

  return (
    <div>
      <button
        className="text-nlpurple border border-nlpurple bg-white rounded-2xl p-2 font-semibold w-24 flex justify-around items-center"
        onClick={() => signOutClickHandler()}
        key="logoutRedirect"
      >
        <ArrowLeftOnRectangleIcon className="h-5 w-5" />
        <span>Logout</span>
      </button>
    </div>
  );
};
