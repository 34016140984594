import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../services/auth/authenticationProvider";
import { fetchOffices } from "../../api/endpoints";
import { ButtonTypes, FilterButton } from "./FilterButton";

interface IOfficeFilterProps {
  currentOfficesSelected: string[];
  handleChange: (level: string) => void;
}
export const OfficeFilters = (props: IOfficeFilterProps) => {
  const { accessToken } = useContext(AuthContext);

  const [offices, setOffices] = useState<string[] | undefined>(undefined);

  const loadOffices = useCallback(async () => {
    if (!accessToken) return;
    const offices = await fetchOffices(accessToken);
    setOffices(offices);
  }, [accessToken]);

  useEffect(() => {
    if (!offices) {
      loadOffices();
    }
  }, [loadOffices, offices]);

  if (!accessToken || !offices) return <div>Loading Offices...</div>;

  return (
    <div>
      <div className="flex flex-row justify-center pb-2 text-nlpurple">
        <div className="text-xl font-semibold">Offices</div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-4">
        {offices.map((office) => (
          <FilterButton
            key={office}
            checked={props.currentOfficesSelected.includes(office)}
            name={office}
            category="offices"
            onClick={props.handleChange}
            type={ButtonTypes.office}
          />
        ))}
      </div>
    </div>
  );
};

export default OfficeFilters;
