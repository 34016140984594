import { useCallback } from "react";
import { PersonsProps, Timespan } from "../Persons";
import TimeframeFilters from "./TimeframeFilters";
import LevelFilters from "./LevelFilters";
import OfficeFilters from "./OfficeFilters";

interface IFilterProps {
  state: PersonsProps;
  isTimespanHidden: boolean
  handleChange: (personsProps: PersonsProps) => void;
}

const Filters = (props: IFilterProps) => {
  const handleInputLevels = useCallback(
    (level: string) => {
      if (props.state.levels?.includes(level)) {
        props.handleChange({
          ...props.state,
          levels: props.state.levels.filter((level1) => level !== level1),
        });
      } else {
        props.handleChange({
          ...props.state,
          levels: [...props.state.levels, level],
        });
      }
    },
    [props]
  );

  const handleInputOffices = useCallback(
    (office: string) => {
      if (props.state.offices?.includes(office)) {
        props.handleChange({
          ...props.state,
          offices: props.state.offices.filter((office1) => office !== office1),
        });
      } else {
        props.handleChange({
          ...props.state,
          offices: [...props.state.offices, office],
        });
      }
    },
    [props]
  );

  const handleInputDay = useCallback(
    (days: number) => {
      props.handleChange({
        ...props.state,
        days: days,
      });
    },
    [props]
  );

  const handleInputTimespan = useCallback(
    (timespan: Timespan) => {
      props.handleChange({
        ...props.state,
        timespan: timespan,
      });
    },
    [props]
  );

  return (
    <div className="rounded-lg shadow px-4 py-2 border border-nllightpurple">
      <div className="flex flex-col gap-2">
        <OfficeFilters
          currentOfficesSelected={props.state.offices}
          handleChange={handleInputOffices}
        />
        <LevelFilters
          currentSelectedLevels={props.state.levels}
          handleChange={handleInputLevels}
        />
        <TimeframeFilters
          currentDay={props.state.days}
          isTimespanHidden={props.isTimespanHidden}
          handleDaysChange={handleInputDay}
          handleTimespanChange={handleInputTimespan}
        />
      </div>
    </div>
  );
};

export default Filters;
