import { Employee } from "../../api/models/employee";
import { AllLeveler, Leveler } from "../../services/leveler/models/leveler";
import { fetchLevels, fetchEmployees, fetchRecentLeveler, fetchProbableLeveler } from "../../api/endpoints"

let levelMap = new Map<string, number>([]);

export const fetchLevelers = async (token: string): Promise<AllLeveler> => {
  const [employees, recentLevelerRefs, probableLevelerRefs, levels] = await Promise.all([
    fetchEmployees(token),
    fetchRecentLeveler(token),
    fetchProbableLeveler(token),
    fetchLevels(token),
  ])

  levels.forEach((value, index) => {
    levelMap.set(value, index)
  });

  let employeeMap = new Map<string, Employee>([])
  let recentLevelers = new Array<Leveler>()
  let probableLevelers = new Array<Leveler>()

  employees.forEach(employee => {
    employeeMap.set(employee.netlightId, employee)
  })

  recentLevelerRefs.forEach(levelerRef => {
    if (!employeeMap.has(levelerRef.netlightId)) {
      return
    }
    const employee = employeeMap.get(levelerRef.netlightId)!
    const leveler = new Leveler(employee, levelerRef.level, levelerRef.onLevelSince, true)
    recentLevelers.push(leveler)
  })

  probableLevelerRefs.forEach(levelerRef => {
    if (!employeeMap.has(levelerRef.netlightId)) {
      return
    }
    const employee = employeeMap.get(levelerRef.netlightId)!
    const leveler = new Leveler(employee, levelerRef.nextLevel, levelerRef.nextLevelFrom, false)
    probableLevelers.push(leveler)
  })

  console.log("Fetched " + employees.length + " employees | recent: " + recentLevelers.length + " | probale: " + probableLevelers.length)
  return {
    recent: recentLevelers,
    probable: probableLevelers
  }
}

export const getLevels = async (): Promise<string[]> => {
  const poll = (resolve: { (value: string[] | PromiseLike<string[]>): void}) => {
    if (levelMap.size > 0) {
      resolve(Array.from(levelMap.keys()))
    } else {
      setTimeout((_: any) => poll(resolve), 100)
    }
  }

  return new Promise(poll)
};

export function sortLevelers(n1: Leveler, n2: Leveler): number {
    let office = n1.office.localeCompare(n2.office);
    let level = sortByLevel(n1, n2);
    let date = sortByDate(n1, n2);
    let name = n1.name.fullName.localeCompare(n2.name.fullName);
  
    return office !== 0 ? office : level !== 0 ? level : date !== 0 ? date : name;
  }
  
  function sortByDate(n1: Leveler, n2: Leveler): number {
    const n1Date = new Date(Date.parse(n1.levelChangeAt));
    const n2Date = new Date(Date.parse(n2.levelChangeAt));
  
    if (n1Date > n2Date) {
      return 1;
    } else if (n1Date < n2Date) {
      return -1;
    }
    return 0;
  }
  
  function sortByLevel(n1: Leveler, n2: Leveler): number {
    return getLevelAsNumber(n1.newLevel) - getLevelAsNumber(n2.newLevel);
  }
  
  const getLevelAsNumber = (level: string): number => {
    if (levelMap.has(level)) {
      return levelMap.get(level)!;
    }
    
    return Number.MAX_SAFE_INTEGER;
  };