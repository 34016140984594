import { exportCSV } from "../services/csv-export";
import { Leveler } from "../services/leveler/models/leveler";
import { Counter } from "./Counter";

import { ArrowDownTrayIcon } from "@heroicons/react/24/solid";

export const ResultHeader = (props: {
  filteredLevelers: Leveler[] | undefined;
}) => {
  return (
    <div className="grid grid-cols-3 align-middle">
      <div></div>
      <Counter leveler={props.filteredLevelers} />
      <div className="flex justify-end">
        <button
          className="bg-nlpurple font-bold rounded-2xl p-2 text-white font-regular w-24 flex items-center justify-around"
          onClick={() => exportCSV(props.filteredLevelers)}
        >
          <ArrowDownTrayIcon className="h-5 w-5" />
          <span>Export</span>
        </button>
      </div>
    </div>
  );
};
