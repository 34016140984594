export enum ButtonTypes {
  level,
  office,
}

export interface IButtonProps {
  name: string;
  checked: boolean;
  category: string;
  onClick: (name: string) => void;
  type: ButtonTypes;
}

export const FilterButton = (props: IButtonProps) => {
  let stylingChecked;
  let stylingUnchecked;
  if (props.type === ButtonTypes.level) {
    stylingChecked = `bg-nllightyellow`;
    stylingUnchecked = `text-nldarkyellow border-nllightyellow`;
  } else {
    stylingChecked = `bg-nllightpurple`;
    stylingUnchecked = `text-nldarkpurple border-nllightpurple`;
  }

  const styling = props.checked
    ? `text-white ${stylingChecked}`
    : `bg-white border ${stylingUnchecked}`;

  return (
    <button
      key={props.name}
      id={props.category}
      aria-describedby="comments-description"
      name={props.name}
      className={`rounded-md justify-center font-bold relative flex items-start py-2 ${styling}`}
      onClick={() => props.onClick(props.name)}
    >
      {props.name}
    </button>
  );
};
