import { SignInButton } from "../components/auth/signinButton";
import { LoginHeader } from "../components/LoginHeader";

const LoginPage = () => {
  return (
    <div className="mx-5 mt-5 flex flex-col gap-2">
      <LoginHeader />
      <SignInButton />
    </div>
  );
};

export default LoginPage;
