import Filters from "./components/filter/Filters";
import { Header } from "./components/Header";
import Persons, { PersonsProps, Timespan } from "./components/Persons";
import { useState } from "react";

function App() {
  const [personsProps, setPersonsProps] = useState<PersonsProps>({
    levels: [],
    offices: [],
    days: 30,
    timespan: Timespan.Past,
  });

  const [isTimespanHidden, setTimespanHidden] = useState(true);
  const handleChange = (filters: PersonsProps) => setPersonsProps(filters);

  return (
    <div className="m-5 flex flex-col gap-2">
      <Header />
      <Filters 
        state={personsProps}
        isTimespanHidden={isTimespanHidden}
        handleChange={handleChange}
      />
      <Persons 
        personProps={personsProps}
        handleHasProbableLevelers={(hasLevelers: boolean) => setTimespanHidden(!hasLevelers)}
      />
    </div>
  );
}

export default App;
