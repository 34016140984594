import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../services/auth/authconfig";

export const SignInButton = () => {
  const { instance } = useMsal();

  const handleLogin = (loginType: string) => {
    if (loginType === "popup") {
      instance.loginPopup(loginRequest);
    } else if (loginType === "redirect") {
      instance.loginRedirect(loginRequest);
    }
  };

  return (
    <div className="flex flex-row p-2 justify-center">
      <button
        onClick={() => handleLogin("redirect")}
        key="loginRedirect"
        color="inherit"
        className="rounded-md font-bold w-20 h-10 bg-nlpurple text-white text-lg"
      >
        Login
      </button>
    </div>
  );
};
